import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
//import Team from "../components/home/team";
import About from "../components/home/about";
import Seo from "../components/seo";

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About Us" />
      <About />
      {/*<Team />*/}
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        apiUrl
      }
    }
  }
`;
