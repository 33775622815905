import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        height: 30,
        width: 30,
        textAlign: "center",
        borderRadius: 10,
        paddingTop: 5,
        paddingBottom: 5,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        height: 30,
        width: 30,
        textAlign: "center",
        borderRadius: 10,
        paddingTop: 5,
        paddingBottom: 5,
      }}
      onClick={onClick}
    />
  );
}

const about = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="page-section bg-light" id="about">
      <div className="container">
        <div className="col-md-12">
          <div>
            <h2 className="section-heading text-uppercase text-center">
              About Me
            </h2>
          </div>

          <h3>Introduction</h3>
          <p style={{ textAlign: "justify", fontSize: "18px" }}>
            Hi there, My name is Anku Singh and i am from Patna, India currently
            living in mohali, punjab, india.{" "}
          </p>
          <p style={{ textAlign: "justify", fontSize: "18px" }}>
            I am <i style={{ fontWeight: "bold" }}>Full stack developer</i> with
            7+ years of experience developing{" "}
            <i style={{ fontWeight: "bold" }}>
              Web Application, Mobile Application, Backend API and AWS Cloud
              Deployment and Development.
            </i>
          </p>
          {/*<p style={{ textAlign: "justify", fontSize: "18px" }}>
            <i style={{ fontWeight: "bold" }}>
              We are group of individual freelancer mainly web designer, mobile
              app developer and full stack developer(Web, Mobile, Backend REST
              API, Cloud Deployment)
            </i>
              </p>*/}
          <h3>Technical skills and expertise</h3>
          <p style={{ textAlign: "justify", fontSize: "18px" }}>
            {" "}
            I have expertise in Web Application, Mobile Application, Rest API,
            AWS Cloud Deployment and development and mainly{" "}
            <i style={{ fontWeight: "bold" }}>
              I work on following skills and have 7+ year of experience in same
            </i>
          </p>
          <div className="d-flex justify-content-center flex-wrap align-items-center mb-2 skill">
            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://www.php.net/"
              >
                <img
                  alt="php"
                  width="40"
                  height="40"
                  src="https://img.icons8.com/dusk/64/000000/php-logo.png"
                />
                <p className="fs-6">PHP</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://www.python.org/"
              >
                <img
                  alt="python"
                  width="40"
                  height="40"
                  src="https://img.icons8.com/color/48/000000/python--v1.png"
                />
                <p className="fs-6">Python</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/javascript.png"
                  alt="javascript"
                  width="40"
                  height="40"
                />
                <p className="fs-6">JavaScript</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://www.typescriptlang.org/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/typescript.png"
                  alt="typescript"
                  width="40"
                  height="40"
                />
                <p className="fs-6">Typescript</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://reactjs.org/"
              >
                <img
                  src="https://img.icons8.com/ultraviolet/40/000000/react.png"
                  alt="python"
                  width="40"
                  height="40"
                />
                <p className="fs-6">React</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://nodejs.org/en/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/nodejs.png"
                  width="40"
                  height="40"
                  alt="nodejs"
                />
                <p className="fs-6">Node JS</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://www.mongodb.com/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/mongodb.png"
                  alt="mongodb"
                  width="40"
                  height="40"
                />
                <p className="fs-6">Mongo DB</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://www.mysql.com/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/mysql-logo.png"
                  alt="mysql"
                  width="40"
                  height="40"
                />
                <p className="fs-6">My SQL</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://angular.io/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/angularjs.png"
                  alt="angular"
                  width="40"
                  height="40"
                />
                <p className="fs-6">Angular</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://laravel.com/"
              >
                <img
                  src="https://img.icons8.com/fluency/48/000000/laravel.png"
                  alt="laravel"
                  width="40"
                  height="40"
                />
                <p className="fs-6">Laravel</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://wordpress.com/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/wordpress.png"
                  alt="wordpress"
                  width="40"
                  height="40"
                />
                <p className="fs-6">WordPress</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://www.gatsbyjs.com/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/gatsbyjs.png"
                  alt="gatsby"
                  width="40"
                  height="40"
                />
                <p className="fs-6">Gatsby</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://flutter.dev/"
              >
                <img
                  src="https://img.icons8.com/fluency/48/000000/flutter.png"
                  alt="flutter"
                  width="40"
                  height="40"
                />
                <p className="fs-6">Flutter</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://firebase.google.com/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/firebase.png"
                  alt="firebase"
                  width="40"
                  height="40"
                />
                <p className="fs-6">Firebase</p>
              </a>
            </div>

            <div
              style={{ maxHeight: 80, minWidth: 100 }}
              className="card box-shadow px-2 pr-2"
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
                target="_blank"
                href="https://graphql.org/"
              >
                <img
                  src="https://img.icons8.com/color/48/000000/graphql.png"
                  alt="graphql"
                  width="40"
                  height="40"
                />
                <p className="fs-6">GraphQL</p>
              </a>
            </div>
          </div>
          <h3 className="mt-5">Certifications</h3>
          <p style={{ textAlign: "justify", fontSize: "18px" }}>
            {" "}
            <i style={{ fontWeight: "bold" }}>
              I am{" "}
              <em>
                AWS Certified Developer Associate and MongoDB Certified
                Developer Associate
              </em>{" "}
              and have full expertise in AWS and MongoDB
            </i>
          </p>
          <div className="d-flex justify-content-center">
            <a
              rel="noreferrer"
              href="https://www.credly.com/badges/0a6b52cb-f18d-49b4-a125-75db92b5a5bd/public_url"
              target="_blank"
            >
              <img
                alt="badge"
                align="right"
                src="https://drive.google.com/uc?export=view&id=105qz5TiUphs1Ok2rohLSo8-GgYUri4p3"
                width="200px"
              />
            </a>
            <a
              rel="noreferrer"
              href="https://university.mongodb.com/certification/certificate/134470667"
              target="_blank"
            >
              <img
                alt="badge"
                align="right"
                src="https://drive.google.com/uc?export=view&id=15k_tI_VjP-nIu2SchpLlb5C651_JNrNw"
                width="200px"
              />
            </a>
          </div>
        </div>
        <div className="mt-5">
          <h3>Testimonials</h3>
        </div>
        <div className="simple white-back quotes no-fouc">
          <Slider {...settings}>
            <img
              src="https://drive.google.com/uc?export=view&id=1wyT3gGQboed-8Xo_IitffSN7Se49t3vO"
              alt="flutter"
            />
            <img
              src="https://drive.google.com/uc?export=view&id=1AE8QwVxje4HQUuOMzreYROlpSCKSbw8E"
              alt="flutter1"
            />
            <img
              src="https://drive.google.com/uc?export=view&id=1jzVaQX8HzgWdVtbG83MuRJyOXi02t8ze"
              alt="flutter1"
            />
            <img
              src="https://drive.google.com/uc?export=view&id=1b-XNUL1EKqEqK5uY6KRXxBMQWyRIMn90"
              alt="flutter1"
            />
          </Slider>
        </div>
        <div className="mt-5">
          <h3>About my journey</h3>
        </div>
        <ul className="timeline mt-5">
          <li>
            <div className="timeline-image">
              <StaticImage
                height={150}
                width={150}
                className="img-fluid about-img mt-1"
                formats={["auto", "webp", "avif"]}
                src="../../images/about1.png"
                quality={95}
                alt="Portfolio1"
              />
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4>March 2015</h4>
                <h4 className="subheading">My Humble Beginning</h4>
              </div>
              <div className="timeline-body">
                <p className="text-muted">
                  I completed my Bachelor of Engineering from Staffordshire
                  university in 2014 and started Job as Web Developer in 2015
                </p>
              </div>
            </div>
          </li>
          <li className="timeline-inverted">
            <div className="timeline-image">
              <StaticImage
                height={150}
                width={150}
                className="img-fluid about-img mt-1"
                formats={["auto", "webp", "avif"]}
                src="https://drive.google.com/uc?export=view&id=105qz5TiUphs1Ok2rohLSo8-GgYUri4p3"
                quality={95}
                alt="Portfolio1"
              />
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4>December 2021</h4>
                <h4 className="subheading">
                  Became AWS and MongoDB Certified Developer
                </h4>
              </div>
              <div className="timeline-body">
                <p className="text-muted">
                  With time passed on i have beed certified as AWS and MongoDB
                  Certified Developer associate
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="timeline-image">
              <StaticImage
                height={150}
                width={150}
                className="img-fluid about-img mt-1"
                formats={["auto", "webp", "avif"]}
                src="../../images/about3.png"
                quality={95}
                alt="Portfolio1"
              />
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4>January 2022</h4>
                <h4 className="subheading">
                  Looking forward for freelancing projects
                </h4>
              </div>
              <div className="timeline-body">
                <p className="text-muted">
                  I'm looking forward for new freelancing project, you can
                  contact me via contact section for Web and Mobile Application
                  development project in Javascript, Python and PHP
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default about;
